'use strict';

/** Constants */
const $body = $('body');

function returnScroll(){
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
  $('#giftcard-modal').remove();
  $('#consent-tracking').remove();

  const htmlString = `
    <div class="modal fade giftcard-modal-sec" id="giftcard-modal" role="dialog" aria-modal="true">
      <span class="giftcard-message sr-only"></span>
      <div class="modal-dialog giftcard-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-body"></div>
        </div>
      </div>
    </div>`;

  $body.append(htmlString);
}

/**
 * wherearegc model html
 *
 **/
function getGCCModalHtmlElement() {
  $('#wherearegcmodal').remove();

  const htmlString = `
    <div class="modal fade wherearegcmodal" id="wherearegcmodal" role="dialog" aria-modal="true">
      <div class="modal-dialog wherearegcmodal-dialog">
        <div class="modal-content">
          <div class="modal-body"></div>
        </div>
      </div>
    </div>`;

  $body.append(htmlString);
}

/**
 * Validation check and actions on the form elements
 *
 * @param {Object} elem - DOM element on which validation should be performed
 */
function validationOfElements(elem) {
  var val = elem.val();
  var parentElement = elem.parent();

  parentElement.toggleClass('error', !val).toggleClass('success', !!val);
  parentElement.find('.error-img').toggleClass('d-none', !!val);
  parentElement.find('.success-img').toggleClass('d-none', !val);
  parentElement.find('.missing-error-required').toggleClass('d-none', !!val);
}

/**
 * validate GC data on blur
 *
 */
function validateOnBlur(elem) {
  if (!elem) {
    $('.giftcard-dialog-body .giftcard-form input:visible').each(function () {
      validationOfElements($(this));
    });
  } else {
    validationOfElements(elem);
  }
}

/**
 * Gift card error notifications
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
  var errorHtml = '<div class="alert alert-danger" role="alert">' + message + '</div>';
  $('.giftcard-error').html(errorHtml);
}

/**
 * fill modal gc form
 *
 * @param {string} urlHit - url to be hit
 */
function fillModalElement(urlHit) {
  $.spinner().start();
  $.ajax({
    url: urlHit,
    method: 'GET',
    dataType: 'json',
    success: function (data) {
      const $giftCardModal = $('#giftcard-modal');
      const template = data.renderedTemplate;
      $giftCardModal.find('.modal-body').html(template);
      $giftCardModal.modal('show');

      // reCaptcha.callToken('giftcardpage');

      $('.svg-svg-22-cross-dims').on('click', function () {
        $('.modal-backdrop').remove();
        $giftCardModal.modal('hide');
      });

      $('.giftcard-dialog')
        .off('blur')
        .on(' blur', '.giftcard-form input', function () {
          validateOnBlur($(this));
        });

      $('.gift-card-check-submit button').on('click', function (e) {
        e.preventDefault();
        $.spinner().start();
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
          // eslint-disable-next-line no-undef
          grecaptcha
            .execute($('.google-recaptcha-key').html(), {
              action: 'gcbalancecheck'
            })
            .then(function (token) {
              $('.giftcard-form .g-recaptcha-token').val(token);
              validateOnBlur();
              const gcNumb = $('.gc-numb').val();
              const gcPin = $('.gc-pin').val();
              const $recaptchToken = $('.g-recaptcha-token');
              if (!gcNumb || !gcPin) {
                $('.generic-error').addClass('d-none');
                $.spinner().stop();
                return;
              }
              $.ajax({
                url: $('.gift-card-check-submit button').data('action'),
                method: 'POST',
                data: {
                  token: $recaptchToken.val(),
                  apiKey: $recaptchToken.data('secret'),
                  gcNumber: gcNumb,
                  gcPin: gcPin,
                  checkbalance: true
                },
                success: function (responseData) {
                  if (responseData.success) {
                    const htmlData = responseData.renderedTemplate;
                    $giftCardModal.find('.modal-body').html(htmlData);
                    $('.svg-svg-22-cross-dims').on('click', function () {
                      returnScroll();
                      const $giftcardModal = $('#giftcard-modal');
                      $giftcardModal.modal('hide');
                      $giftcardModal.remove();
                      $body.removeClass('modal-open');
                      $('.modal-backdrop').remove();
                    });
                    $('.generic-error').addClass('d-none');
                    $.spinner().stop();
                    $('.giftcard-continue button').on('click', function (event) {
                      event.preventDefault();
                      returnScroll();
                      const $giftcardModal = $('#giftcard-modal');
                      $giftcardModal.modal('hide');
                      $giftcardModal.remove();
                      $body.removeClass('modal-open');
                      $('.modal-backdrop').remove();
                    });
                    $('.giftcard-check-balance button').on('click', function (eve) {
                      eve.preventDefault();
                      var url = $(this).data('action');
                      fillModalElement(url);
                    });
                  } else if (typeof responseData.serviceError !== 'undefined' && responseData.serviceError) {
                    $('.generic-error').addClass('d-none');
                    createErrorNotification(responseData.message);
                    // reCaptcha.callToken('giftcardpage');
                    $.spinner().stop();
                  } else {
                    const $gcNumbParent = $('.gc-numb').parent();
                    const $gcPinParent = $('.gc-pin').parent();

                    $gcNumbParent.find('.success-img').addClass('d-none');
                    $gcPinParent.find('.success-img').addClass('d-none');
                    $('.generic-error').html(responseData.message).removeClass('d-none');
                    $gcNumbParent.addClass('error');
                    $gcNumbParent.find('.error-img').removeClass('d-none');
                    $gcPinParent.addClass('error');
                    $gcPinParent.find('.error-img').removeClass('d-none');
                    // reCaptcha.callToken('giftcardpage');
                    $.spinner().stop();
                  }

                  if (responseData.botError) {
                    $('.check-bal-bttn').attr('disabled', 'disabled');
                    createErrorNotification(responseData.error);
                  }
                },
                error: function (responseData) {
                  const $gcNumbParent = $('.gc-numb').parent();
                  const $gcPinParent = $('.gc-pin').parent();

                  $gcNumbParent.find('.success-img').addClass('d-none');
                  $gcPinParent.find('.success-img').addClass('d-none');
                  // $('.generic-error').html(responseData.message);
                  $('.generic-error').removeClass('d-none');
                  $gcNumbParent.addClass('error');
                  $gcNumbParent.find('.error-img').removeClass('d-none');
                  $gcPinParent.addClass('error');
                  $gcPinParent.find('.error-img').removeClass('d-none');
                  // reCaptcha.callToken('giftcardpage');
                  $.spinner().stop();

                  if (responseData.botError) {
                    $('.check-bal-bttn').attr('disabled', 'disabled');
                    createErrorNotification(responseData.error);
                  }
                }
              });
            });
        });
      });
      $.spinner().stop();
    },
    error: function () {
      $.spinner().stop();
    }
  });
}

/**
 * wherearegc model actions.
 *
 **/
function fillGCCModalElement() {
  $.spinner().start();

  const $wherearegcmodal = $('#wherearegcmodal');
  const hideModal = function () {
    $wherearegcmodal.attr('aria-modal', 'false');
    $wherearegcmodal.next('.modal-backdrop').remove();
    $wherearegcmodal.modal('hide');
  };

  $.ajax({
    url: $('a.wherearegc').attr('href'),
    method: 'GET',
    success: function (data) {
      const template = data.renderedTemplate;
      $('.wherearegcmodal-dialog .modal-body').html(
        '<button class="close gc-close-btn svg-svg-22-cross svg-svg-22-cross-dims" type="button"></button>' + template
      );
      $wherearegcmodal.modal('show');

      $('.svg-svg-22-cross-dims').on('click', hideModal);

      $.spinner().stop();
    },
    error: function () {
      hideModal();
      $.spinner().stop();
    }
  });
}

/**
 * show check balance modal
 *
 * @param {HTMLElement} eventTargetElement - event target element with url data
 **/
function showCheckBalanceModal(eventTargetElement) {
  var target = eventTargetElement || $('.check-balance')[0];
  var urlHit = $(target).attr('href');
  getModalHtmlElement();
  fillModalElement(urlHit);
}

$('.check-balance').on('click', function (e) {
  e.preventDefault();
  showCheckBalanceModal(this);
});

$body.on('click', '.wherearegc', function (e) {
  e.preventDefault();
  getGCCModalHtmlElement();
  fillGCCModalElement();
});

showCheckBalanceModal(); // show modal after async script load
